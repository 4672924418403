<template>
  <div class="m-2">
    <div class="content-header my-3">
      <!-- /.modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <!-- <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="exampleModalLabel"> Data Detail</h5>
                </div> -->
            <div class="modal-body">
              <h6 class="text-bold">Informasi Transaksi</h6>
              <div class="row">
                <div class="col-sm-3">Nomor Transaksi</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.transaction_code">
                    {{ dataDetail.transaction_code }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Id Vending</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.vending.vending_id">
                    {{ dataDetail.vending.vending_id }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Nama Vending</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.vending.name">
                    {{ dataDetail.vending.name }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Alamat Vending</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.vending.address">
                    {{ dataDetail.vending.address }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Tanggal Transaksi</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.created">
                    {{ formatDate(dataDetail.created) }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Jumlah Produk</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.total_amount">
                    {{ dataDetail.total_amount }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Total Harga</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.total_cost">
                    {{ dataDetail.total_cost }}</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">Status</div>
                <div class="col-sm-1 text-right">:</div>
                <div class="col-sm-6">
                  <label v-if="dataDetail.status">
                    {{ dataDetail.status }}</label
                  >
                </div>
              </div>

              <h6 class="title mt-2 text-bold">Detail Produk</h6>
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th><label> Nama Produk </label></th>
                    <th><label> Harga Produk </label></th>
                    <th><label> Jumlah Produk </label></th>
                    <th><label> Total Harga Produk </label></th>
                    <th><label> Barang Jatuh </label></th>
                    <th><label> Barang Tidak Jatuh </label></th>
                  </tr>
                </thead>
                <tbody v-for="items in dataDetail.detail" :key="items.id">
                  <tr>
                    <td>{{ items.product.name }}</td>
                    <td>{{ items.product_fee }}</td>
                    <td>{{ items.amount }}</td>
                    <td>{{ items.total_product_fee }}</td>
                    <td>
                      <div v-if="items.quantity_success">
                        {{ items.quantity_success }}
                      </div>
                      <div v-else>0</div>
                    </td>
                    <td>
                      <div v-if="items.quantity_error">
                        {{ items.quantity_error }}
                      </div>
                      <div v-else>0</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark capitalize">Report Daftar Penjualan</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <!-- <ol class="breadcrumb float-sm-right ">
                        <li>
                            <button type="button" class="btn btn-block btn-outline-primary btn-lg">Export</button>
                        </li>
                        <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"  @click="showModal"><span class="fas fa-plus"> </span>  Add Merchant</button></li>
                    </ol> -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
        <div class="alert alert-danger alert-dismissible" v-if="error">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-hidden="true"
          >
            &times;
          </button>
          <h5>
            <i class="icon fa fa-ban"></i>
            failed!
          </h5>
          <p>{{ error }}</p>
        </div>
        <div class="row mb-2">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="recipient-vendingId" class="col-form-label"
                >Vending</label
              >
              <input
                type="text"
                class="form-control"
                id="recipient-vendingId"
                v-model="vendingId"
              />
            </div>
          </div>
          <!-- /.col -->
          <div class="col-sm-2">
            <div class="form-group">
              <label for="recipient-status" class="col-form-label"
                >Status</label
              >
              <select class="form-control" v-model="status">
                <option value="">Semua Status</option>
                <option value="Paid">Paid</option>
                <option value="Pending">Pending</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
          </div>
          <!-- /.col -->
          <div class="col-sm-2">
            <div class="form-group">
              <label for="recipient-dateStart" class="col-form-label"
                >Date Mulai</label
              >
              <input
                type="date"
                class="form-control"
                id="recipient-dateStart"
                v-model="dateStart"
              />
            </div>
          </div>
          <!-- /.col -->
          <div class="col-sm-2">
            <div class="form-group">
              <label for="recipient-dateEnd" class="col-form-label"
                >Date Selesai</label
              >
              <input
                type="date"
                class="form-control"
                id="recipient-dateEnd"
                v-model="dateEnd"
              />
            </div>
          </div>
          <!-- /.col -->
          <div class="col-sm-4 align-self-end">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-primary btn-lg"
                @click="btnDownload"
              >
                Download
              </button>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <data-tables-master-data
      :config="config"
      @click="clickRow"
      id="sampleTable"
    ></data-tables-master-data>
  </div>
</template>

<script>
const { console } = window;
import DataTablesMasterData from "@components/DataTablesMasterData.vue";
export default {
  components: {
    DataTablesMasterData,
  },

  data() {
    return {
      vendingId: "",
      status: "",
      dateStart: "",
      dateEnd: "",
      error: undefined,
      dataDetail: {
        created: null,
        transaction_code: null,
        total_amount: null,
        total_cost: null,
        status: null,
        vending: {
          vending_id: null,
          name: null,
          address: null,
        },
        detail: {},
      },
      config: {
        columns: [
          {
            title: "Tanggal",
            data: "created",
            render: (val) => {
              return this.formatDate(val);
            },
          },
          {
            title: "No Pesanan",
            data: "transaction_code",
          },
          {
            title: "Merchant",
            data: "merchant.name",
          },
          {
            title: "ID Vending",
            data: "vending.vending_id",
          },
          {
            title: "Status",
            data: "status",
          },
          {
            title: "Nilai Transaksi",
            data: "total_cost",
            render: (val) => {
              return this.formatPrice(val);
            },
          },
        ],
        url: this.Api.base + "/transaction",
        order: [[0, "desc"]],
      },
    };
  },
  mounted() {},
  props: {
    value: Object,
  },
  methods: {
    clickRow(row) {
      if (row.data()) {
        // this.form.id=row.data().id;
        // this.form.name=row.data().name;
        // this.form.sku=row.data().sku;
        // this.form.image=row.data().image;
        this.dataDetail = row.data();
        $("#exampleModal").modal("show");
      }
    },
    formatDate(value) {
      return moment(String(value)).format("DD-MMM-YYYY HH:mm");
    },
    formatPrice(value) {
      return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    btnDownload() {
      if ((this.dateStart != "") & (this.dateEnd != "")) {
        let linkurl =
          "/report/download/sale/" + this.dateStart + "/" + this.dateEnd + "";
        let saveAs = "laporan_penjualan.xls";
        let filters =
          '?filters=[["vending.vending_id","like","' +
          this.vendingId +
          '"],["and"],["status","like","' +
          this.status +
          '"]]';
        linkurl = linkurl + filters;
        Api.download(linkurl, saveAs)
          .then(() => {
            console.log("download complete");
            this.error = null;
          })
          .catch((err) => {
            this.error = "Data tidak tersedia dalam laporan";
          });
      } else {
        this.error = "Data Tanggal Mulai dan Tanggal Selesai Harus Diisi";
      }
    },
  },
};
</script>
<style>
.fMfcaV .order .title {
  padding: 0px 0px 10px;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  position: relative;
  margin: 0px 0px 10px;
}
</style>
